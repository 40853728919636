html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
}

.app-content {
    height: 100%;
}

.app-page {
    min-height: 100%;
}

div.background-image {
    height: auto;
    min-height: 100%;
    min-width: 100%;
    background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute;
}

.mbsc-ios.mbsc-control-w:before, .mbsc-ios.mbsc-control-w:after {
    left: 0 !important;
}

.mbsc-form.net-form-width-xl {
    max-width: 1199px;
    margin: auto;
}

.mbsc-form.net-form-width-lg {
    max-width: 991px;
    margin: auto;
}

@media (min-width: 768px) {

    .rccs {
        xmargin: 0 !important;
    }

    .mbsc-form .net-form-header {
        padding-bottom: 1em;
    }

    .mbsc-windows.mbsc-form .rccs  {
        padding-top: 1.625em;
    }

}

@media (max-width: 767px) {

    .hide-on-small-screen {
        display: none;
    }
    .rccs {
        padding-top: 1em;
    }

}
